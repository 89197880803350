import React, { useState, useEffect } from 'react'
import { Box, Collapse, Button, Text, Grid, Card, Group, Avatar, Menu, ActionIcon, HoverCard, Stack, Badge } from '@mantine/core'
import { IconDots, IconTrash, IconEdit, IconChevronDown } from '@tabler/icons-react'
import dayjs from 'dayjs'
import { getImageFile } from '../../../../services/fileImage'
import { deleteAnswerDiscussion } from '../../../../services/answerDiscussion'
import { useDisclosure } from '@mantine/hooks'
import { useSelector } from 'react-redux'
import { modalDeleteData } from '../../../ui/ModalManager/modalDeleteData'
import FormAnswerDiscussionProgram from './FormAnswerDiscussionProgram'


const AnswerDiscussionProgram = ({ detailDiscussion, dataAnswer, reloadList }) => {
  const loginData = useSelector(state => state.member.data)
  const [opened, { toggle }] = useDisclosure(false)
  const [answerDiscussion, setAnswerDiscussion] = useState([])
  const [detailAnswer, setDetailAnswer] = useState(null)

  const handleEditAnswer = (val, index) => {
    const data = answerDiscussion
    data[index].editAnswer = true
    setAnswerDiscussion([...data])
    setDetailAnswer(val)
  }

  const handleDeleteAnswer = (val) => {
    modalDeleteData('Jawaban Diskusi', val.id, detailDiscussion.title, deleteAnswerDiscussion, reloadList)
  }

  const cancelEditAnswer = (index) => {
    const data = answerDiscussion
    data[index].editAnswer = false
    setAnswerDiscussion([...data])
    setDetailAnswer(null)
  }

  useEffect(() => {
    if (dataAnswer) {
      setAnswerDiscussion(dataAnswer)
    }
  }, [dataAnswer])

  
  const remapDataAnswerDiscussion = (answerList) => {
    //eslint-disable-next-line
    const remap = answerList.map((val, index) => {
      const innerHtmlDiscussion = { __html: val.content }

      const permissionAdjustment = (userId) => {
        if (loginData.id === userId) {
          return (
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon variant="subtle" color="gray">
                  <IconDots size={14} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item 
                  leftSection={<IconEdit size={14} />}
                  fz={11}
                  onClick={() => handleEditAnswer(val, index)}
                >
                  Edit Balasan
                </Menu.Item>
                <Menu.Item
                  leftSection={<IconTrash size={14} />}
                  color="red"
                  fz={11}
                  onClick={() => handleDeleteAnswer(val)}
                >
                  Hapus Balasan
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )
        }
      }

      if (!val.editAnswer) {
        if (loginData.id === val.userEvent.user.id) {
          return (
            <Box my={20}>
              <Grid type="container" justify="flex-end" mb={12}>
                <Grid.Col span={11} order={{ base: 2, sm: 1, md: 1, lg: 1 }}>
                  <Card pos='relative' withBorder shadow="md" radius="md">
                    <Card.Section withBorder inheritPadding py="xs">
                      <Group justify={val.userEvent.isTutor ? 'space-between' : 'flex-end'} align='center'>
                        {val.userEvent.isTutor ? <Badge size="xs" variant="gradient" gradient={{ from: 'red', to: 'violet', deg: 90 }}> Tutor </Badge> : ''}
                        <Group gap={4}>
                          <Text fz={12} c='dimmed'>{dayjs(val.createdAt).format('DD MMMM YYYY')}</Text>
                          {permissionAdjustment(val.userEvent.user.id)}
                        </Group>
                      </Group>
                    </Card.Section>
                    <Text fz={11} fw='600' mt={10}>{val.userEvent.user.fullName}: </Text>
                    <Box component='p' style={{ width: '100%', margin:'0px' }} className="ck-content" dangerouslySetInnerHTML={innerHtmlDiscussion} />
                  </Card>
                </Grid.Col>
                <Grid.Col span='content' order={{ base: 1, sm: 2, md: 2, lg: 2 }}>
                  <Group>
                    <HoverCard width={250} shadow="md" withArrow openDelay={200} closeDelay={400}>
                      <HoverCard.Target>
                        <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Group>
                          <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                          <Stack gap={5}>
                            <Text size="sm" fw={700} lineClamp={1} style={{ lineHeight: 1 }}>
                              {val.userEvent.user.fullName}
                            </Text>
                            <Badge variant="light" color="red" size="xs" tt='capitalize'>{!val.userEvent.isTutor ? 'Member' : 'Tutor'}</Badge>
                          </Stack>
                        </Group>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                </Grid.Col>
              </Grid>
            </Box>
          )
        } else {
          return (
            <Box my={20}>
              <Grid type="container" justify="flex-start" mb={12}>
                <Grid.Col span='content'>
                  <Group>
                    <HoverCard width={250} shadow="md" withArrow openDelay={200} closeDelay={400}>
                      <HoverCard.Target>
                        <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                      </HoverCard.Target>
                      <HoverCard.Dropdown>
                        <Group>
                          <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                          <Stack gap={5}>
                            <Text size="sm" fw={700} lineClamp={1} style={{ lineHeight: 1 }}>
                              {val.userEvent.user.fullName}
                            </Text>
                            <Badge variant="light" color="red" size="xs" tt='capitalize'>{!val.userEvent.isTutor ? 'Member' : 'Tutor'}</Badge>
                          </Stack>
                        </Group>
                      </HoverCard.Dropdown>
                    </HoverCard>
                  </Group>
                </Grid.Col>
                <Grid.Col span={11}>
                  <Card pos='relative' withBorder shadow="md" radius="md">
                    <Card.Section withBorder inheritPadding py="xs">
                      <Group justify={val.userEvent.isTutor ? 'space-between' : 'flex-end'} align='center'>
                        {val.userEvent.isTutor ? <Badge size="xs" variant="gradient" gradient={{ from: 'red', to: 'violet', deg: 90 }}> Tutor </Badge> : ''}
                        <Group gap={4}>
                          <Text fz={12} c='dimmed'>{dayjs(val.createdAt).format('DD MMMM YYYY')}</Text>
                          {permissionAdjustment(val.userEvent.user.id)}
                        </Group>
                      </Group>
                    </Card.Section>
                    <Text fz={11} fw='600' mt={10}>{val.userEvent.user.fullName}: </Text>
                    <Box component='p' style={{ width: '100%', margin:'0px' }} className="ck-content" dangerouslySetInnerHTML={innerHtmlDiscussion} />
                  </Card>
                </Grid.Col>
              </Grid>
            </Box>
          )
        }
      } else {
        return (
          <Box my={20}>
            <FormAnswerDiscussionProgram detailDiscussion={detailDiscussion} detailAnswer={detailAnswer} cancelReplyQuestion={() => cancelEditAnswer(index)} handleReload={reloadList}  />
          </Box>
        )
      }

      
    })

    return remap
  }

  if (dataAnswer.length > 0) {
    return (
      <Box>
        <Box ta="center">
          <Button size='compact-xs' rightSection={<IconChevronDown size={12} />} color='red.6' variant="subtle" onClick={toggle}>Lihat Balasan</Button>
        </Box>
        <Collapse in={opened} mt={12}>
          {remapDataAnswerDiscussion(answerDiscussion)}
        </Collapse>
      </Box>
      
    )
  } else {
    return (
      <Box my={10} />
    )
  }
  
}

export default AnswerDiscussionProgram