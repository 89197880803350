import React, { useState, useEffect } from 'react'
import { Box, Flex, Button, Input } from '@mantine/core'
import { addAnswerDiscussion, updateAnswerDiscussion } from '../../../../services/answerDiscussion'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { 
  ClassicEditor, 
  Bold, 
  Essentials, 
  Italic, 
  Mention, 
  Paragraph, 
  Undo, 
  Font, 
  Heading,
  Image,
  ImageCaption,
  ImageResizeEditing, 
  ImageResizeHandles,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageInline,
  ImageBlock,
  LinkImage,
  ImageUpload,
  ImageInsertViaUrl,
  List,
  MediaEmbed,
  Alignment
} from 'ckeditor5'
import { validation } from '../../../../plugins/validation'
import { useParams } from 'react-router-dom'
// import { useSelector } from 'react-redux'
import { notificationSuccess, notificationError } from '../../../ui/Notification'

const defaultVal = {
  content: '',
}

const formValidation = {
  content: {
    isError: false,
    message: ''
  },
}

const FormAnswerDiscussionProgram = ({ detailDiscussion, detailAnswer, cancelReplyQuestion, handleReload }) => {
  const params = useParams()
  const programId = params.id
  // const dataMember = useSelector(state => state.member.data)
  const [formData, setFormData] = useState(defaultVal)
  const [validationForm, setValidationForm] = useState(formValidation)
  const [loadingForm, setLoadingForm] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  const uploadAdapter = (loader) => {
    return {
      upload: async () => {
        const file = await loader.file
        const base64 = await toBase64(file)
        return {
          default: base64
        }
      },
    }
  }

  const toBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

  const configEditor = {
    licenseKey: 'GPL',
    extraPlugins: [ uploadPlugin ],
    plugins:[
      Bold,
      Essentials,
      Italic,
      Mention,
      Paragraph,
      Undo,
      Font,
      Heading,
      Image,
      ImageCaption,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageInline,
      ImageBlock,
      LinkImage,
      ImageUpload,
      ImageResizeEditing, 
      ImageResizeHandles,
      ImageInsertViaUrl,
      List,
      MediaEmbed,
      Alignment
    ],
    alignment: {
      options: [ 'left', 'right' ]
    },
    fontSize: {
      options: [
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
          17,
          18,
          19,
          20,
          21,
          22
      ],
      supportAllValues: true
    },
    image: {
      resizeUnit: "%",
			resizeOptions: [
        {
            name: 'resizeImage:original',
            value: null,
            label: 'Original'
        },
        {
            name: 'resizeImage:custom',
            label: 'Custom',
            value: 'custom'
        },
        {
            name: 'resizeImage:40',
            value: '40',
            label: '40%'
        },
        {
            name: 'resizeImage:60',
            value: '60',
            label: '60%'
        }
      ],
      toolbar: [
        'resizeImage',
        '|',
        'imageStyle:alignBlockLeft',
        'imageStyle:alignBlockRight',
        '|',
        'imageStyle:alignCenter',
        '|',
        'imageStyle:alignLeft',
        'imageStyle:alignRight',
        '|',
        'toggleImageCaption',
        'imageTextAlternative',
        '|',
        'linkImage'
      ],
      insert: {
          integrations: [ 'upload', 'url' ]
      }
    },
    list: {
      properties: {
          styles: true,
          startIndex: true,
          reversed: true
      }
    },
    toolbar: {
      items: [
        'undo', 'redo',
        '|',
        'heading',
        '|',
        'fontSize', 'Bold', 'Italic', 'alignment',
        '|',
        'link', 'insertImage',
        '|',
        'mediaEmbed',
        '|',
        'bulletedList', 'numberedList',
        '|',
      ],
      shouldNotGroupWhenFull: true
    },
    initialData: `${formData.content}`
  }

  const submitReplyDiscussion = async () => {
    setLoadingForm(true)
    setValidationForm(formValidation)
    let methodFunction = null
    let titleMessageSuccess = ''
    let captionMessageSuccess = ''
    let titleMessageError = ''
    let captionMessageError = ''

    const isError = validation(formData, setValidationForm)
    if (isError) {
      setLoadingForm(false)
      return
    }
    if (detailAnswer !== null) {
      const updatePayload = {
        content: formData.content
      }
      methodFunction = updateAnswerDiscussion(detailAnswer.id, updatePayload)
      titleMessageSuccess = 'Update Jawaban Diskusi Berhasil'
      captionMessageSuccess = 'Anda telah mengupdate jawaban diskusi baru'
      titleMessageError = 'Update Jawaban Diskusi Gagal'
    } else {
      const createPayload = {
        content: formData.content,
        userEventId: programId,
        discussionId: detailDiscussion.id
      }
      methodFunction = addAnswerDiscussion(createPayload)
      titleMessageSuccess = 'Penambahan Jawaban Diskusi Berhasil'
      captionMessageSuccess = 'Anda telah menambahkan jawaban diskusi baru'
      titleMessageError = 'Penambahan Jawaban Diskusi Gagal'
    }
    try {
      const response = await methodFunction
      if (response) {
        handleReload()
        cancelReplyQuestion()
        notificationSuccess(titleMessageSuccess, captionMessageSuccess)
      }
    } catch (error) {
      const errorMessage = error.response.data.message
      captionMessageError = Object.keys(errorMessage) ? errorMessage : 'Silahkan cek kembali form anda'
      notificationError(titleMessageError, captionMessageError)
      Object.values(errorMessage).forEach((el) => {
        Object.keys(formValidation).forEach((element) => {
          if (el.includes(element)) {
            setValidationForm((old) => ({
              ...old,
              [element]: {
                ...old?.[element],
                isError: true,
                message: el
              }
            }))
          }
        })
      })
    } finally {
      setLoadingForm(false)
    }
  }

  useEffect(() => {
    if (detailAnswer !== null) {
      handleSetForm(detailAnswer)
    }
    setIsLoaded(true)
    // eslint-disable-next-line
  }, [detailAnswer])

  const handleSetForm = (value) => {
    const data = {
      content: value.content
    }
    setFormData(data)
  }

  const handleChangeForm = (name, val) => {
    setFormData((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const initCKeditor = () => {
    return (
      <CKEditor
          editor={ClassicEditor}
          config={configEditor}
          data={formData.content}
          onReady={(editor) => {
            editor.execute('fontSize', { value: '12px' })
            editor.editing.view.change((writer) => {
              writer.setStyle(
                'height',
                '250px',
                editor.editing.view.document.getRoot()
              )
            })
          }}
          onChange={( event, editor ) => {
            editor.execute('fontSize', { value: '13px' })
            const valueEditor = editor.getData()
            handleChangeForm('content', valueEditor)
          }}
        />
    )
  }
  
  return (
    <Box px={40}>
      {/* <Text mb={20} fw='bold'>{detail !== null ? 'Edit' : 'Tambah'} Jawaban Diskusi {detail.title}</Text> */}
      <Box>
        <Box mb='md'>
          <Input.Wrapper 
            label="Konten Jawaban"
            withAsterisk
            error={validationForm.content.isError ? `${validationForm.content.message}` : ''}
          >
            {isLoaded ? initCKeditor() : ''}
          </Input.Wrapper>
        </Box>
      </Box>
      <Box mt={14}>
        <Flex justify='flex-end' mb={14}>
          <Button size='xs' mr={6} onClick={cancelReplyQuestion}>Batal</Button>
          <Button color='red' loading={loadingForm} size='xs' onClick={() => submitReplyDiscussion()}>{detailAnswer !== null ? 'Edit' : 'Tambah'} Jawaban</Button>
        </Flex>
      </Box>
    </Box>
  )
}

export default FormAnswerDiscussionProgram