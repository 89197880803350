import React from 'react'
import PublicLayout from '../../../layouts/PublicLayout'
import classes from './DetailCouse.module.css'
import BgHeaderEvent from '../../../assets/img/bg-event.webp'
import { Box, Container, Text, Button, Grid, Card, Rating, Flex, Group, List, rem, ThemeIcon } from '@mantine/core'
import { IconClipboardText, IconNotebook, IconMessage, IconCheckupList, IconVideo, IconCertificate } from '@tabler/icons-react'
import { getImageFile } from '../../../services/fileImage'
import { formatPrice } from '../../../plugins/formatPrice'
import VideoIntroCourse from './VideoIntroCourse'
import InfoCourse from './InfoCourse'
import { useLocalStorage } from '@mantine/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { update } from '../../../store/cartData'

const defaultRoute = {
  key: 'route',
  defaultValue: ''
}

const DetailCourse = ({ detail, loading }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [, setRoute] = useLocalStorage(defaultRoute)
  const { isLogin } = useSelector(state => state.auth)

  const handleImage = (filepath) => {
    if (filepath.length > 0) {
      return getImageFile(detail.eventImages[0].filepath)
    } else {
      return BgHeaderEvent
    }
  }

  const handleCheckout = (scheduleId, schedulePrice) => {
    const data = {
      transactionMethodId: null,
      eventScheduleIds: [ scheduleId ],
      eventSlug: params.slug,
      totalPrice: schedulePrice
    }
    dispatch(update({ 'cart': data }))
    if (!isLogin) {
      navigate("/login")
      setRoute('/checkout')
    } else {
      navigate("/checkout")
    }
  }
  return (
    <PublicLayout>
      <Box className={classes.headerSection} style={{ backgroundImage: `linear-gradient(250deg, rgba(130, 201, 30, 0) 0%, #ece6e6 70%), url(${!loading ? handleImage(detail.eventImages) : ''})`}}>
        <Container fluid className={classes.section}>
          <Grid align="stretch">
            <Grid.Col span={{ base: 12, md: 12, lg: 12 }} >
              <Card shadow="sm" padding="lg" radius="md" withBorder>
                <Box ta='center' className={classes.paddingPage}>
                  <Text className={classes.titleHeader} mb={16}>{detail.name}</Text>
                  <Text className={classes.captionHeader} mb={24}>{detail.description}</Text>
                  <Flex justify='center' align='center'>
                    <Rating size='md' defaultValue={5} fractions={5} readOnly />
                  </Flex>
                  <Button className={classes.buttonHeader} fullWidth mt={20} onClick={() => handleCheckout(detail.eventSchedules[0].id, detail.eventSchedules[0].price)}>Beli Kelas</Button>
                </Box>
                <Box className={classes.paddingPage} mt={20}>
                  <Text fw="bold">Kelas Sudah termasuk</Text>
                  <Group grow my={20}>
                    <List spacing="xs" size="sm" center>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconClipboardText style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Ujian Akhir
                      </List.Item>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconNotebook style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Dokumen
                      </List.Item>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconMessage style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Diskusi
                      </List.Item>
                    </List>
                    <List spacing="xs" size="sm"center>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconCheckupList style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Pre-Test
                      </List.Item>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconVideo style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Video
                      </List.Item>
                      <List.Item
                        icon={
                          <ThemeIcon color="red.6" size={24} radius="xl">
                            <IconCertificate style={{ width: rem(16), height: rem(16) }} />
                          </ThemeIcon>
                        }
                      >
                        Sertifikat
                      </List.Item>
                    </List>
                  </Group>
                </Box>
                <Box className={classes.paddingPage} mt={10}>
                  <Group justify='space-between' align='center'>
                    <Box>
                      <Text fw="bold" mb={8}>Bahasa Pengantar</Text>
                      <Text>Bahasa Indonesia</Text>
                    </Box>
                    <Box>
                      <Text className={classes.priceSection} fw="bold" c="red">{formatPrice(detail.price)}</Text>
                    </Box>
                  </Group>
                </Box> 
              </Card>
            </Grid.Col>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.defaultSection}>
        <Container fluid className={classes.section}>
          <Box className={classes.paddingPage}>
            <VideoIntroCourse curriculum={detail.curriculums} />
            <InfoCourse title={detail.name} desc={detail.longDescription} />
          </Box>
        </Container>
      </Box>
    </PublicLayout>
    
  )
} 

export default DetailCourse