import http from "../plugins/axios"

export const getDiscussionList = async (params) => {
  try {
    const response = await http.get(`/discussions`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addDiscussion = async (payload) => {
  try {
    const response = await http.post(`/discussions`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateDiscussion = async (discussionId ,payload) => {
  try {
    const response = await http.patch(`/discussions/${discussionId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteDiscussion = async (discussionId) => {
  try {
    const response = await http.delete(`/discussions/${discussionId}`)
    return response.data
  } catch(error) {
    throw error
  }
}