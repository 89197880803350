import React, { useEffect } from 'react'
import { MantineProvider, LoadingOverlay } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { ModalsProvider } from '@mantine/modals'
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom'
import { routes, memberRouter } from './routes'
import { useDispatch, useSelector } from 'react-redux'
import { getCredential, update } from './store/authData'
import { getAuthMember, dataMember } from './store/authMember'
import { Worker } from '@react-pdf-viewer/core'

// css mantine
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import '@mantine/dates/styles.css'

// css ck-editor
import 'ckeditor5/ckeditor5.css'

export default function App() {
  const { isLoading, isLogin } = useSelector(state => state.auth)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCredential())
  }, [dispatch])

  useEffect(() => {
    if (!isLoading) {
      if (isLogin) {
        dispatch(getAuthMember())
      } else {
        dispatch(update({ 'authorization': null, 'isLogin': false, 'isLoading': false }))
        dispatch(dataMember({ 'data': null, 'isLoading': false }))
        if (pathname.includes('/auth/verify') && pathname.includes('/password-resets/verify')) {
          return navigate(pathname)
        }
      }
    }
    // eslint-disable-next-line
  }, [isLoading])

  // init load page to top page
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const styleUI = {
    fontFamily: "Montserrat, sans-serif",
    headings: { 
      fontFamily: 'Montserrat, sans-serif'
    },
  }

  // eslint-disable-next-line
  const getRoute = (allRoutes) => allRoutes.map((page) => {
    if (!page.hasChildren) {
      return (
        <Route path={page.route} element={page.component}  key={page.name} />
      )
    } else {
      return (
        <Route path={page.route} key={page.name}>
          {childrenRoute(page.children)}
        </Route>
      )
    }
  })

  // eslint-disable-next-line
  const childrenRoute = (arrayRoute) => arrayRoute.map((children) => {
    if (children) {
      if (children.route === null) {
        return (
          <Route index element={children.component} key={children.name} />
        )
      } else {
        return (
          <Route path={children.route} element={children.component} key={children.name} />
        )
      }
    }
  })

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <MantineProvider theme={styleUI}>
        <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "md", blur: 3 }} />
        <Notifications autoClose={4000} position="top-right" limit={5} />
        <ModalsProvider>
          <Routes>
            {getRoute(routes)}
            {getRoute(memberRouter)}
          </Routes>
        </ModalsProvider>
      </MantineProvider>
    </Worker>
  )
}
