import React, { useState } from 'react'
import { AppShell, Box, Text, NavLink, Checkbox, Badge, ScrollArea, Button } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import HeaderMember from '../../../ui/AppShell/HeaderMember'
import { IconBookDownload, IconClipboardText, IconFileCheck, IconFileInfo, IconArrowNarrowLeft, IconVideo, IconPlayerPlay, IconMessages } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import InfoProgram from './InfoProgram'
import TaskProgram from './TaskProgram'
import ModuleProgram from './ModuleProgram'
import RecordingProgram from './RecordingProgram'
import VideoCourseProgram from './VideoCourseProgram'
import DiscussionProgram from './DiscussionProgram'

const badgeStatus = {
  pending: {
    label: 'Belum Dikerjakan',
    color: 'yellow'
  },
  fail: {
    label: 'Belum Lulus',
    color: 'red'
  },
  passed: {
    label: 'Lulus',
    color: 'green'
  }
}

const curriculumStatus = {
  open: {
    label: 'Terbuka',
    color: 'blue'
  },
  closed: {
    label: 'Tutup',
    color: 'red'
  },
  done: {
    label: 'Selesai',
    color: 'green'
  }
}

const defaultState = {
  id: null,
  type: ''
}

const LayoutProgramDetail = ({ detail, videoProgram, tutor, handleReload }) => {
  const navigate = useNavigate()
  const [openSidebarDesktop, { toggle: toggleDesktop }] = useDisclosure(true)
  const [openSidebarMobile, { toggle: toggleMobile }] = useDisclosure()
  const [sidebarActive, setSidebarActive] = useState('information')
  const [stateContent, setStateContent] = useState(defaultState)

  const handleGroupingCurriculum = (data) => {
    const groupCurriculum = {}
    const remap = data.reduce((result, val) => {
      const { curriculumContent } = val
      const curriculumId = curriculumContent.curriculum.id
      if (!groupCurriculum[curriculumId]) {
        groupCurriculum[curriculumId] = {
          id: curriculumId,
          name: curriculumContent.curriculum.name,
          curriculumSchedule: []
        }
        result.push(groupCurriculum[curriculumId])
      }
      groupCurriculum[curriculumId].curriculumSchedule.push(val)
      return result
    }, [])
    return remap
  }

  const handleStateContent = (stateId, typeTask) => {
    setStateContent(defaultState)
    const data = {
      id: stateId,
      type: typeTask
    }
    setStateContent(data)
  }

  const mappingTask = (taskCurriculum) => {
    if (taskCurriculum) {
      const mappingStatus = (totalAnswerAttempt, isPassTask) => {
        if (totalAnswerAttempt.length > 0 && !isPassTask) {
          return `fail`
        } else if (totalAnswerAttempt.length > 0 && isPassTask) {
          return 'passed'
        } else if (totalAnswerAttempt.length === 0 && !isPassTask) {
          return 'pending'
        }
      }
      const remapTask = taskCurriculum.map((val, index) => {
        return (
          <NavLink
            active={val.id === sidebarActive}
            py={12}
            key={index}
            leftSection={<Checkbox checked={val.answerAttempts.length > 0 ? true : false} />}
            rightSection={<Badge size="xs" radius="sm" variant="filled" color={badgeStatus[mappingStatus(val.answerAttempts, val.isPassed)].color}>{badgeStatus[mappingStatus(val.answerAttempts, val.isPassed)].label}</Badge>}
            label={<Text fz='14' fw='500' lineClamp={2}>{val.name}</Text>}
            onClick={() => [setSidebarActive(val.id), handleStateContent(val.id, 'task'), toggleMobile()]}
            color='#E72E12'
          />
        )
      })
      return remapTask
    }
  }

  const listCurriculum = (scheduleCurriculum) => {
    // eslint-disable-next-line
    const remapData = scheduleCurriculum.map((val, index) => {
      if (val.curriculumContent.type === 'url') {
        return (
          <NavLink
            active={val.curriculumContent.id === sidebarActive}
            py={14} 
            key={index}
            leftSection={<IconBookDownload size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculumContent.name}</Text>}
            rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
            onClick={() => [setSidebarActive(val.curriculumContent.id), handleStateContent(val.curriculumContent.id, val.curriculumContent.type), toggleMobile()]}
            color='#E72E12'
            disabled={val.status === 'closed'}
          />
        )
      } else if (val.curriculumContent.type === 'task') {
        return (
          <NavLink
            py={14}
            key={index}
            leftSection={<IconFileCheck size="1.3rem" stroke={1.5} />} 
            label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculumContent.name}</Text>}
            childrenOffset={0}
            rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
            disableRightSectionRotation={true}
            color='#E72E12'
            disabled={val.status === 'closed'}
          >
            {mappingTask(val.curriculumContent.tasks)}
          </NavLink>
        )
      } else if (val.curriculumContent.type === 'file') {
        return (
          <NavLink
            active={val.curriculumContent.id === sidebarActive}
            py={14} 
            key={index}
            leftSection={<IconBookDownload size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculumContent.name}</Text>}
            rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
            onClick={() => [setSidebarActive(val.curriculumContent.id), handleStateContent(val.curriculumContent.id, val.curriculumContent.type), toggleMobile()]}
            color='#E72E12'
            disabled={val.status === 'closed'}
          />
        )
      } else if (val.curriculumContent.type === 'video') {
        return (
          <NavLink
            active={val.curriculumContent.id === sidebarActive}
            py={14} 
            key={index}
            leftSection={<IconPlayerPlay size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>{val.curriculumContent.name}</Text>}
            rightSection={<Badge size="xs" radius="sm" variant="filled" color={curriculumStatus[val.status].color}>{curriculumStatus[val.status].label}</Badge>}
            onClick={() => [setSidebarActive(val.curriculumContent.id), handleStateContent(val.curriculumContent.id, val.curriculumContent.type), toggleMobile()]}
            color='#E72E12'
            disabled={val.status === 'closed'}
          />
        )
      }
    })

    return remapData
  }

  const mappingSidebarCurriculum = (typeEvent, curriculums) => {
    if (curriculums.length > 0) {
      const dataCurriculum = handleGroupingCurriculum(curriculums)
      const remapCurriculumTask = dataCurriculum.map((val, index) => {
        return (
          <NavLink
            py={14} 
            key={index}
            leftSection={<IconClipboardText size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={1}>{val.name}</Text>}
            childrenOffset={0}
            active
          >
            {listCurriculum(val.curriculumSchedule)}
          </NavLink>
        )
      })

      const recordingMenu = () => {
        const listRecording = (video) => {
          if (video.length > 0) {
            const remapList = video.map((val, index) => {
              return (
                <NavLink
                  active={val.id === sidebarActive}
                  py={14} 
                  key={index}
                  leftSection={<IconPlayerPlay size="1.3rem" stroke={1.5} />}
                  label={<Text fz='12' fw='500' lineClamp={2}>{val.name}</Text>}
                  onClick={() => [setSidebarActive(val.id), handleStateContent(val.id, 'recording'), toggleMobile()]}
                  color='#E72E12'
                />
              )
            })
            return remapList
          }
        }

        return (
          <NavLink
            py={14} 
            leftSection={<IconVideo size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>Rekaman </Text>}
            childrenOffset={0}
            active
          >
            {listRecording(videoProgram)}
          </NavLink>
        )
      }

      const mappingMenuDiscussion = (type) => {
        if (type === 'course') {
          return (
            <NavLink
              py={14} 
              active={sidebarActive === 'discussion'}
              leftSection={<IconMessages size="1.3rem" stroke={1.5} />}
              label={<Text fz='12' fw='500' lineClamp={2}>Diskusi</Text>}
              onClick={() => setSidebarActive('discussion')}
              color='#E72E12'
            />
          )
        }
      }

      return (
        <Box p='md'>
          <NavLink
            py={14} 
            leftSection={<IconFileInfo size="1.3rem" stroke={1.5} />}
            label={<Text fz='12' fw='500' lineClamp={2}>Informasi</Text>}
            onClick={() => setSidebarActive('information')}
            color='#FFB01B'
            variant='filled'
            active
          />
          {videoProgram.length > 0 ? recordingMenu() : ''}
          {remapCurriculumTask}
          {mappingMenuDiscussion(typeEvent)}
        </Box>
      )
    } else {
      return (
        <Box ta='center' mt={20}>
          <Text fz={12}>Program ini belum memiliki materi</Text>
        </Box>
      )
    }
  } 

  const displayProgram = () => {
    if (sidebarActive === 'information') {
      return <InfoProgram detailProgram={detail} tutorProgram={tutor} handleReload={handleReload} />
    } else if (sidebarActive === 'discussion') {
      return <DiscussionProgram detailProgram={detail} />
    } else {
      if (stateContent.type === 'task') {
        return <TaskProgram detail={stateContent} />
      } else if (stateContent.type === 'recording') {
        return <RecordingProgram detailRecording={videoProgram.find(val => val.id === stateContent.id)} />
      } else if (stateContent.type === 'video') {
        return <VideoCourseProgram detail={stateContent} /> 
      } else {
        return <ModuleProgram detail={stateContent} />
      }
    }
  }
  return (
    <AppShell
      header={{ height: 80 }}
      navbar={{
        width: 350,
        breakpoint: 'sm',
        collapsed: { mobile: !openSidebarMobile, desktop: !openSidebarDesktop }
      }}
      padding='md'
    >
      <AppShell.Header>
        <HeaderMember mobileOpened={openSidebarMobile} mobileToggle={toggleMobile} desktopOpened={openSidebarDesktop} desktopToggle={toggleDesktop} />
      </AppShell.Header>
      <AppShell.Navbar>
        <AppShell.Section grow component={ScrollArea}>
          {mappingSidebarCurriculum(detail.eventSchedule.event.type, detail.eventSchedule.curriculumSchedules)}
        </AppShell.Section>
      </AppShell.Navbar>
      <AppShell.Main>
        <Box>
          <Button variant="subtle" leftSection={<IconArrowNarrowLeft />} color='red.9' px={0} mb={10} onClick={() => navigate("/program")}>Kembali ke Program Saya</Button>
          <Box>
            {displayProgram()}
          </Box>
        </Box>
      </AppShell.Main>
    </AppShell>
  )
}

export default LayoutProgramDetail