import React, { useState, useEffect, useRef } from 'react'
import { getDetailContent, finishedContentProgress, getDetailUserProgram } from '../../../../services/userPrograms'
import { useParams } from 'react-router-dom'
import { Box, Center, Loader, Text, Card } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { update } from '../../../../store/memberProgram'
import Plyr from 'plyr-react'
import Hls from "hls.js"

import 'plyr-react/plyr.css'
import '../../../../assets/css/hls-player-member.css'

const LoadingCourse = () => {
  return (
    <Box pos="relative"> 
      <Center mih={500} mx="auto">
        <Loader size="xl" />
      </Center>
    </Box>
  )
}

const VideoCourseProgram = ({ detail }) => {
  const supportedHls = Hls.isSupported()
  const player = useRef(null)
  const dispatch = useDispatch()
  const params = useParams()
  const programId = params.id
  const [detailContent, setDetailContent] = useState(null)
  const [loading, setLoading] = useState(true)

  const defaultOptionsPlayer = {
    quality: {
      default: 360,
      options: [240, 360, 480, 720, 1080],
      forced: true,
      onChange: (e) => updateQualityPlayer(e),
    }
  }

  const handleDetailProgram = async () => {
    try {
      const response = await getDetailUserProgram(programId)
      dispatch(update({ 'data': response }))
    } catch (error) {
      console.log(error)  
    } 
  }

  const handleGetDetailContent = async (contentId) => {
    setLoading(true)
    try {
      const response = await getDetailContent(programId, contentId)
      if (response) {
        setDetailContent(response)
        handleFinishedProgress(response.id)
        await handleLoadVideo(response)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    } 
  }
  
  const handleFinishedProgress = async(contentId) => {
    const payload = {
      status: 'passed'
    }
    try {
      await finishedContentProgress(programId, contentId, payload)
      await handleDetailProgram()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleLoadVideo = async () => {
    const videoPlayer = document.getElementById("plyr")
    var hls = new Hls()
    hls.loadSource(detailContent.module)
    hls.attachMedia(videoPlayer)
    player.current.plyr.media = videoPlayer
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      // eslint-disable-next-line
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        for (let i = 0; i < data.levels.length; i++) {
          if (data.levels[i].height >= player.config.quality.default) {
            hls.currentLevel = i
            player.quality = player.config.quality.default
            break
          }
        }
      })
    })
    // hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //   player.current.plyr.play()
    // })
  }

  useEffect(() => {
    if (detail !== null) {
      handleGetDetailContent(detail.id)
    }
  //eslint-disable-next-line
  }, [detail])

  useEffect(() => {
    if (detailContent !== null) {
      handleLoadVideo()
    }
  // eslint-disable-next-line
  }, [detailContent])

  const updateQualityPlayer = (newQuality) => {
    if (window.hls && window.hls.levels && window.hls.levels.length > 0) {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex
        }
      })
    }
  }

  const loadDataCourse = (data) => {
    return (
      <Box mih={500} pos='relative'>
        <Text fz={20} fw="bold">Video Course {data.name}</Text>
        <Box mt={20} h={600}>
          <Card shadow="md" padding="lg" radius="lg" withBorder>
              <Card.Section>
                {supportedHls ? <Plyr id='plyr' options={defaultOptionsPlayer} source={{}} ref={player} /> : 'HLS is not supported in your browser'}
              </Card.Section>
            </Card>
        </Box>
      </Box>
    )
  }

  
  return (
    <Box>
      {loading ? (<Box mih={500} pos='relative'><LoadingCourse /></Box>) : loadDataCourse(detailContent)}
    </Box>
  )
}

export default VideoCourseProgram