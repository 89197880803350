import React, { useEffect, useState } from 'react'
import { getProgrambySlug } from '../../../services/program'
import { useParams } from 'react-router-dom'
import { LoadingOverlay, Box } from '@mantine/core'
import { HelmetProvider } from 'react-helmet-async'
import DetailCourse from '../../../components/pages/Course/DetailCourse'

const defaultVal = {
  name: '',
  description: '',
  type: '',
  eventSchedules: [],
  curriculums: [],
  eventImages: [],
  tutors: []
}

const LearningSlug = () => {
  const params = useParams()
  const slug = params.slug
  const [detailProgram, setDetailProgram] = useState(defaultVal)
  const [loading, setLoading] = useState(true)

  const handleGetDetailProgram = async () => {
    setLoading(true)
    try {
      const response = await getProgrambySlug(slug)
      setDetailProgram(response)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetDetailProgram()
    // eslint-disable-next-line
  }, [])

  return (
    <HelmetProvider>
      <Box pos='relative'>
        <LoadingOverlay visible={loading} overlayBlur={2} />
        <DetailCourse detail={detailProgram} loading={loading} />
      </Box>
    </HelmetProvider>
  )
}

export default LearningSlug