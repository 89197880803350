import React from 'react'
import { Box, Container, Grid, Text, Paper, Flex } from '@mantine/core'
import classes from '../../../pages/Home/Home.module.css'
import { useHover } from '@mantine/hooks'
import { Flip } from '@gfazioli/mantine-flip'
import { IconPointFilled } from '@tabler/icons-react'

import '@gfazioli/mantine-flip/styles.layer.css'

const IntroCard = ({ value }) => {
  const { hovered, ref } = useHover()

  const mappingCaption = (caption) => {
    if (caption !== 'list-data') {
      return (<Text fz={10} fw={600}>{caption}</Text>)
    } else {
      return (
        <Box>
          <Flex align='start' gap={4} mb={2}> 
            <IconPointFilled stroke={1.5} size={20} />
            <Text fz={10} fw={600} mt={3}>Telah melakukan In House Training kepada puluhan Industri dan BUMN</Text>
          </Flex>
          <Flex align='start' gap={4}>
            <IconPointFilled stroke={1.5} size={20} />
            <Text fz={10} fw={600} mt={3}>Menjadi penghubung untuk banyak Alumni dengan Industri</Text>
          </Flex>
        </Box>
      )
    }
  }

  return (
    <Flip ref={ref} h={120} direction="vertical" directionFlipIn="positive" directionFlipOut="negative" flipped={hovered} style={{ cursor: 'pointer' }}>
      <Paper h={120} radius="md" withBorder p="lg" shadow="md">
        <Text className={classes.titleIntro} mb={8}>{value.title}</Text>
        <Text fz={10} fw={600}>{value.desc}</Text>
      </Paper>

      <Paper h={120} radius="md" withBorder p="lg" shadow="md" style={{ backgroundColor: '#F3901F' }}>
        {mappingCaption(value.caption)}
      </Paper>

    </Flip>
  )

}

const IntroSection = () => {
  const defaultIntroText = [
    {
      title: 'Acceleration',
      desc: 'Mempercepat perkembangan kompetensi individu di dunia kerja',
      caption: 'Telah melakukan pelatihan untuk lebih dari 500 Alumni pelatihan profesional'
    },
    {
      title: 'Capacity Building',
      desc: 'Meningkatkan kemampuan individu dengan modul terkini dan tutor berkompeten',
      caption: 'Menggaet lebih dari 20 Tutor dari kalangan akademisi, praktisi dan professional yang telah tersertifikasi dibidangnya'
    },
    {
      title: 'Professional',
      desc: 'Memberikan Pelatihan yang professional dan sesuai dengan kebutuhan perusahaan',
      caption: 'Berhasil membantu lebih dari 50 Alumni lulus sertifikasi BNSP (Badan Nasional Sertifikasi Profesi)'
    },
    {
      title: 'Collaborative',
      desc: 'Berkerjasama dengan berbagai perusahaan dalam berbagai program',
      caption: 'list-data'
    },
  ]

  const mappingIntroCard = (data) => data.map((val, index) => {
    return (
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }} key={index}>
        <IntroCard value={val} />
      </Grid.Col>
    )
  })
  return (
    <Box className={classes.customSection}>
      <Container fluid className={classes.section}>
        <Box className={classes.paddingPage}>
          <Grid align='start' gutter='lg'>
            {mappingIntroCard(defaultIntroText)}
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default IntroSection