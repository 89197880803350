import React, { useState, useEffect } from 'react'
import { getUserProgramList } from '../../../../services/userPrograms'
import { Box, Group, Button, Text, Paper, Card, Flex, Avatar, Skeleton } from '@mantine/core'
import classes from '../../../../pages/Member/MemberPage.module.css'
import { useNavigate } from 'react-router-dom'
import { IconCircleArrowRight } from '@tabler/icons-react'
import { getImageFile } from '../../../../services/fileImage'
import TimeAgo from 'react-timeago'
import idnString from 'react-timeago/lib/language-strings/id'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import dayjs from 'dayjs'


const defaultParameter = {
  take: 3,
  order: 'desc'
}

const UpcomingProgramMember = () => {
  const formatter = buildFormatter(idnString)
  const navigate = useNavigate()
  const [ programList, setProgramList ] = useState([])
  const [ loading, setLoading ] = useState(true)

  const startProgram  = (startDate) => {
    const now = dayjs().unix()
    const expired = dayjs(startDate).subtract(7, 'hours').unix()
    return now > expired
  }

  const handleUserProgramList = async () => {
    setLoading(true)
    try {
      const response = await getUserProgramList(defaultParameter)
      setProgramList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleUserProgramList()
    // eslint-disable-next-line
  }, [])

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i}>
        <Skeleton height={75} width="100%" radius="md" mb="xl" />
      </Box>
    )
  })

  const mappingProgram = (training) => {
    if (training.length > 0) {
      // eslint-disable-next-line
      const mappingData = training.map((val) => {
        if (!startProgram(val.eventSchedule.eventStart)) {
          return (
            <Card shadow='sm' padding='md' radius='md' mb={14} withBorder key={val.eventSchedule.event.name} onClick={() => navigate(`/program/${val.id}`)}>
              <Flex justify='space-between'>
                <Flex gap="xs">
                  <Avatar size="lg" src={val.eventSchedule.event.eventImages.length > 0 ? getImageFile(val.eventSchedule.event.eventImages[0].filepath) : null } alt={val.eventSchedule.event.name} />
                  <Box w="70%">
                    <Text fz={14} fw="bold" lineClamp={1}>{val.eventSchedule.event.name}</Text>
                    <Text fz={12} c='gray.6' lineClamp={1}>{val.eventSchedule.event.kind === 'course' ? 'Course' : val.eventSchedule.name}</Text>
                  </Box>
                </Flex>
                <Text fz={12}><TimeAgo date={val.eventSchedule.eventStart} formatter={formatter} /></Text>
              </Flex>
            </Card>
          )
        }
      })
      return mappingData
    } else {
      return (
        <Box my={50}>
          <Text ta="center" color='dimmed'>Anda belum mengikuti program apapun</Text>
        </Box>
      )
    }
  }

  return (
    <Box>
      <Group justify='space-between' mb={12}>
        <Text className={classes.titleDashboard} fw='bold'>Program Mendatang</Text>
        <Button rightSection={<IconCircleArrowRight />} color='yellow.7' radius="md" size='xs' onClick={() => navigate('/program')}>Selengkapnya</Button>
      </Group>
      <Paper withBorder radius='md' p={12} h={330}>
        {loading ? loadingData(3) : (<Box>{mappingProgram(programList)}</Box>)}
      </Paper>
    </Box>
  )
}

export default UpcomingProgramMember