import React, { useState, useEffect, useRef } from 'react'
import { Box, Text, Card, Grid, Accordion, ScrollArea, Group, ThemeIcon } from '@mantine/core'
import Plyr from 'plyr-react'
import Hls from "hls.js"
import { IconPlayerPlay, IconLock } from '@tabler/icons-react'

import 'plyr-react/plyr.css'
import '../../../assets/css/hls-player.css'

const VideoIntroCourse = ({ curriculum }) => {
  const supportedHls = Hls.isSupported()
  const player = useRef(null)
  const [activeIndexCurriculum, setActiveIndexCurriculum] = useState(null)
  const [activeIndexSubCurriculum, setActiveIndexSubCurriculum] = useState(null)

  const selectedPlayedVideo = () => {
    if (activeIndexCurriculum !== null) {
      return curriculum[activeIndexCurriculum].curriculumContents[activeIndexSubCurriculum].module
    }
    return ''
  }

  const defaultOptionsPlayer = {
    quality: {
      default: 360,
      options: [240, 360, 480, 720, 1080],
      forced: true,
      onChange: (e) => updateQualityPlayer(e),
    }
  }

  const updateQualityPlayer = (newQuality) => {
    if (window.hls && window.hls.levels && window.hls.levels.length > 0) {
      window.hls.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          window.hls.currentLevel = levelIndex
        }
      })
    }
  }

  const handleLoadVideo = async () => {
    const selectedDataPlayer = selectedPlayedVideo()
    const videoPlayer = document.getElementById("plyr")
    var hls = new Hls()
    hls.loadSource(selectedDataPlayer)
    hls.attachMedia(videoPlayer)
    player.current.plyr.media = videoPlayer
    hls.on(Hls.Events.MEDIA_ATTACHED, function () {
      // eslint-disable-next-line
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        for (let i = 0; i < data.levels.length; i++) {
          if (data.levels[i].height >= player.config.quality.default) {
            hls.currentLevel = i
            player.quality = player.config.quality.default
            break
          }
        }
      })
    })
    // hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //   player.current.plyr.play()
    // })
  }

  const handleGetPromotionVideo = (dataCurriculum) => {
    for (let i = 0; i < dataCurriculum.length; i++) {
      for (let j = 0; j < dataCurriculum[i].curriculumContents.length; j++) {
        if (dataCurriculum[i].curriculumContents[j].videoHost === 'dyntube' && dataCurriculum[i].curriculumContents[j].isFree) {
          if (activeIndexCurriculum === null) {
            setActiveIndexCurriculum(i)
            setActiveIndexSubCurriculum(j)
          }
        }
      }
    }
  }

  useEffect(() => {
    if (curriculum !== null) {
      handleGetPromotionVideo(curriculum)
    }
    // eslint-disable-next-line
  }, [curriculum])

  useEffect(() => {
    if (activeIndexCurriculum !== null) {
      handleLoadVideo()
    }
    // eslint-disable-next-line
  }, [activeIndexCurriculum])




  const mappingCurriculum = (curriculum) => {

    const remapCurriculumContent = (indexCurriculum, content) => {

      const changeVideoPromotion = (indexCurriculum, indexContent) => {
        setActiveIndexCurriculum(indexCurriculum)
        setActiveIndexSubCurriculum(indexContent)
        handleLoadVideo()
      }

      const mappingIconContent = (indexContent, freeContent, typeContent, sourceContent, ) => {
        if (freeContent && typeContent === 'video' && sourceContent === 'dyntube') {
          return (
            <ThemeIcon variant='white' color={indexContent === activeIndexSubCurriculum ? 'red.6' : 'indigo.9'}>
              <IconPlayerPlay stroke={1.5} size={18} />
            </ThemeIcon>
          )
        } else {
          return (
            <ThemeIcon variant='white' color='gray.5'>
              <IconLock stroke={1.5} size={18} />
            </ThemeIcon>
          )
        }
      }

      if (content.length > 0) {
        const remapDataContent = content.map((val, index) => {
          return (
            <Card shadow={0} radius={0} padding='xs' withBorder style={{ cursor: val.isFree ? 'pointer' : 'default' }} onClick={() => val.isFree && val.videoHost === 'dyntube' ? changeVideoPromotion(indexCurriculum, index) : ''}>
              <Group gap='xs' align='center'>
                {mappingIconContent(index, val.isFree, val.type, val.videoHost)}
                <Text fz={12} fw={index === activeIndexSubCurriculum ? '600' : '500'} c={val.isFree && val.videoHost === 'dyntube' ? '' : 'dimmed'}>{val.name}</Text>
              </Group>
            </Card>
          )
        })
        return remapDataContent
      }
    }

    if (curriculum.length > 0) {
      const remapCurriculum = curriculum.map((val, index) => {
        return (
          <Accordion.Item key={index} value={val.id}>
            <Accordion.Control style={{ padding: '4px 14px' }}>
              <Text fz={12} fw="bold">{val.name}</Text>
            </Accordion.Control>
            <Accordion.Panel>{remapCurriculumContent(index, val.curriculumContents)}</Accordion.Panel>
          </Accordion.Item>
        )
      })
      return remapCurriculum
    }
  }

  return (
    <Box mb={35}>
      <Grid align="stretch">
        <Grid.Col span={{ base: 12, md: 8, lg: 8 }} >
          <Card shadow="md" padding="lg" radius="lg" withBorder>
            <Card.Section>
              {supportedHls ? <Plyr id='plyr' options={defaultOptionsPlayer} source={{}} ref={player} /> : 'HLS is not supported in your browser'}
            </Card.Section>
          </Card>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 4, lg: 4 }} style={{ maxHeight: '500px' }}>
          <Card shadow="sm" padding={0} radius="md" withBorder>
            <Box px={12} pt={18} py={10}>
              <Text fw="bold" mb={8}>Materi</Text>
            </Box>
            <ScrollArea h={420} type="always" scrollbarSize={8}>
              <Accordion radius={0} variant='contained' styles={{ content: { padding: '0px' } }}>
                {mappingCurriculum(curriculum)}
              </Accordion>
            </ScrollArea>
          </Card>
        </Grid.Col>
      </Grid>
    </Box>
  )
}

export default VideoIntroCourse