import http from "../plugins/axios"

export const getAnswerDiscussionList = async (params) => {
  try {
    const response = await http.get(`/discussion-answers`, { params })
    return response.data
  } catch(error) {
    throw error
  }
}

export const addAnswerDiscussion = async (payload) => {
  try {
    const response = await http.post(`/discussion-answers`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const updateAnswerDiscussion = async (answerDiscussionId ,payload) => {
  try {
    const response = await http.patch(`/discussion-answers/${answerDiscussionId}`, payload)
    return response.data
  } catch(error) {
    throw error
  }
}

export const deleteAnswerDiscussion = async (answerDiscussionId) => {
  try {
    const response = await http.delete(`/discussion-answers/${answerDiscussionId}`)
    return response.data
  } catch(error) {
    throw error
  }
}