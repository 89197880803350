import React, { useState } from 'react'
import classes from '../../../../pages/Member/MemberPage.module.css'
import dayjs from 'dayjs'
import { Box, Title, Text, Image, Card, Avatar, Flex, Group, Button, Grid , Badge, ActionIcon, Progress, Accordion, Modal } from '@mantine/core'
import { IconInfoSquareRounded, IconSchool, IconCertificate, IconMap2, IconDownload, IconInfoCircle, IconAlertTriangleFilled } from '@tabler/icons-react'
import { modals } from '@mantine/modals'
import { getImageFile } from '../../../../services/fileImage'
import GmeetLogo from '../../../../assets/img/Gmeet.png'
import ZoomLogo from '../../../../assets/img/ZoomLogo.svg'
import MsTeamLogo from '../../../../assets/img/MsTeamLogo.png'
import NoImage from '../../../../assets/img/no-image.png'
import { useSelector } from 'react-redux'
import FormFeedbackProgram from './FormFeedbackProgram'

const InfoProgram = ({ detailProgram, tutorProgram, handleReload }) => {
  const [modalFeedback, setModalFeedback] = useState(false)
  const progressProgram = useSelector(state => state.program.data)
  const innerHtml = { __html: detailProgram.eventSchedule.event.longDescription }

  const onCloseModalFeedback = () => {
    setModalFeedback(false)
  }

  const mappingTypeEvent = (scheduleEvent) => {
    if (scheduleEvent.event.type === 'offline') {
      const handleMapLink = (link) => {
        window.open(`${link}`, '_blank')
      }
      return (
        <Box>
          <Badge color="green" variant="filled" mb={14}>
            {scheduleEvent.event.type}
          </Badge>
          <Text fz="xs" fw='500' mb={10}>Alamat: <Text fz='xs' component='span' fw="bold">{scheduleEvent.address}</Text></Text>
          <Group spacing="xs">
            <ActionIcon color="red">
              <IconMap2 size="1.5rem" />
            </ActionIcon>
            <Text fz="xs">Klik <Text component='span' fz='xs' fw="bold" c='red' style={{ cursor: 'pointer' }} onClick={() => handleMapLink(scheduleEvent.mapLink)}>disini</Text> untuk menunjukkan peta lokasi</Text>
          </Group>
        </Box>
      )
    } else if (scheduleEvent.event.type === 'online') {
      const logoMeeting = () => {
        if (scheduleEvent.onlineLink.includes('zoom')) {
          return ZoomLogo
        } else if (scheduleEvent.onlineLink.includes('teams')) {
          return MsTeamLogo
        } else if (scheduleEvent.onlineLink.includes('meet.google')) {
          return GmeetLogo
        }
      }
      const openUrl = (onlineLink) => {
        let url = ''
        const checkUrl = !/^https?:\/\//.test(onlineLink)
        if (checkUrl) {
          url = `https://` + onlineLink
        } else {
          url = onlineLink
        }
        // eslint-disable-next-line
        window.open(`${url}`, '_blank')
      }
      return (
        <Box>
          <Badge color="green" variant="filled" mb={14}>
            {scheduleEvent.event.type}
          </Badge>
          <Text fz="sm" fw="500">Link Meeting</Text>
          <Flex gap="xs" align='center' my={14}>
            <Image src={logoMeeting()} w={100} />
            <Text fz="xs">Klik <Text component='span' fz='xs' fw="bold" c='red' style={{ cursor: 'pointer' }} onClick={() => openUrl(scheduleEvent.onlineLink)}>disini</Text> untuk menuju link pertemuan</Text>
          </Flex>
        </Box>
      )
    } else if (scheduleEvent.event.type === 'hybrid') {
      const logoMeeting = () => {
        if (scheduleEvent.onlineLink.includes('zoom')) {
          return ZoomLogo
        } else if (scheduleEvent.onlineLink.includes('teams')) {
          return MsTeamLogo
        } else if (scheduleEvent.onlineLink.includes('meet.google')) {
          return GmeetLogo
        }
      }
      const openUrl = (onlineLink) => {
        let url = ''
        const checkUrl = !/^https?:\/\//.test(onlineLink)
        if (checkUrl) {
          url = `https://` + onlineLink
        } else {
          url = onlineLink
        }
        // eslint-disable-next-line
        window.open(`${url}`, '_blank')
      }
      const handleMapLink = (link) => {
        window.open(`${link}`, '_blank')
      }
      return (
        <Box>
          <Badge color="green" variant="filled" mb={10}>
            {scheduleEvent.event.type}
          </Badge>
          <Box mb={20}>
            <Text fz="xs" fw='500' mb={10}>Alamat: <Text fz='xs' component='span' fw="bold">{scheduleEvent.address}</Text></Text>
            <Group spacing="xs">
              <ActionIcon color="red">
                <IconMap2 size="1.5rem" />
              </ActionIcon>
              <Text fz="xs">Klik <Text component='span' fz='xs' fw="bold" c='red' style={{ cursor: 'pointer' }} onClick={() => handleMapLink(scheduleEvent.mapLink)}>disini</Text> untuk menunjukkan peta lokasi</Text>
            </Group>
          </Box>
          <Box>
            <Text fz="xs" fw='500'>Link Meeting</Text>
            <Flex gap="xs" align='center' my={14}>
              <Image src={logoMeeting()} w={100} />
              <Text fz="xs">Klik <Text component='span' fz='xs' fw="bold" c='red' style={{ cursor: 'pointer' }} onClick={() => openUrl(scheduleEvent.onlineLink)}>disini</Text> untuk menuju link pertemuan</Text>
            </Flex>
          </Box>
        </Box>
      )
    }
  }

  const mappingDescriptionTutor = (tutorEvent) => {
    const openModalTutor = (data, innerHtml) => modals.open({
      title: 'Informasi Tutor',
      centered: true,
      withCloseButton: false,
      withBorder: true,
      children: (
        <Box my={20}>
          <Box align='center' mb={14}>
            <Avatar radius="md" size={120} src={data.user.image !== "" ? getImageFile(data.user.image) : null} alt={data.user.fullName} />
          </Box>
          <Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>Nama: </Text>
              <Text fz={13}>{data.user.fullName}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>Email: </Text>
              <Text fz={13}>{data.user.email}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>Nomor Telepon / HP: </Text>
              <Text fz={13}>{data.user.phone}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>Linked In : </Text>
              <Text fz={13}>http://{data.user.linkedin}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>{data.user.status !== 'lecturer' ? 'Pekerjaan:' : 'Departemen:'} </Text>
              <Text fz={13}>{data.user.job}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>{data.user.status !== 'lecturer' ? 'Perusahaan:' : 'Institusi:'} </Text>
              <Text fz={13}>{data.user.company}</Text>
            </Box>
            <Box mb={8}>
              <Text fz={13} fw='bold'>Profile Tutor: </Text>
              <Box fz={13} mt={0} dangerouslySetInnerHTML={innerHtml} />
            </Box>
          </Box>
          <Flex justify='flex-end'>
            <Button onClick={() => modals.closeAll()} mt="md">
              Tutup
            </Button>
          </Flex>
        </Box>
      )
    })

    if (tutorEvent.length > 0) {
      const mappingTutor = tutorEvent.map((val, index) => {
        const innerHtml = { __html: val.shortProfile }
        return (
          <Grid.Col span={{ base: 12, lg: 12 }} key={index}>
            <Card shadow="sm" padding="md" radius="md" withBorder>
              <Flex justify='space-between' align='start'>
                <Group align='center'>
                  <Avatar radius="md" size={120} src={val.user.image !== "" ? getImageFile(val.user.image) : null} alt={val.user.fullName} />
                  <Box>
                    <Text mb={2} fz="sm" fw="bold">{val.user.fullName}</Text>
                    <Text mb={8} fz="sm" fw='bold'>{val.user.company}</Text>
                    <Text mb={2} fz="sm">{val.user.job}</Text>
                  </Box>
                </Group>
                <Button c='red' leftSection={<IconInfoCircle size={14} />} variant='transparent' onClick={() => openModalTutor(val, innerHtml)}> Detail </Button>
              </Flex>
            </Card>
          </Grid.Col>
        )
      })
      return mappingTutor
    } else {
      return (
        <Grid.Col span={{ base: 12, lg: 4 }}>
          <Card shadow="sm" padding="md" radius="md" withBorder>
            <Group align='center'>
              <Avatar radius="md" size={120} src={null} alt='katalis-tutor' />
              <Box>
                <Text mb={2} fz="sm" fw="bold">Professional Tutor</Text>
                <Text fz="xs">Katalis Talenta Indonesia</Text>
              </Box>
            </Group>
          </Card>
        </Grid.Col>
      )
    }
  }

  const mappingInfoEvent = (eventKind) => {
    if (eventKind !== 'course') {
      return (
        <Box>
          <Box my={16}>
            <Text mb={4} fz="sm" fw="bold">Waktu Pelaksanaan</Text>
            <Text fz="xs">{dayjs(detailProgram.eventSchedule.eventStart).format('DD-MM-YYYY')} - {dayjs(detailProgram.eventSchedule.eventEnd).format('DD-MM-YYYY')}</Text>
          </Box>
          <Box my={16}>
            <Text mb={4} fz="sm" fw="bold">Tipe Pelaksanaan</Text>
            {mappingTypeEvent(detailProgram.eventSchedule)}
          </Box>
        </Box>
      )
    }
  }

  const fileRundownTraining = (fileRundown) => {
    if (fileRundown !== null) {
      const openUrl = () => {
        // eslint-disable-next-line
        window.open(`${process.env.REACT_APP_SERVER_API_URL}/files/storage/` + `${fileRundown}`, '_blank')
      }

      return (
        <Flex justify='end' mb={16}>
          <Button size="xs" leftSection={<IconDownload size="1rem" />} color='red' onClick={openUrl}>Rundown</Button>
        </Flex>
      )
    }
  }

  const certificateTraining = (dataCertificate) => {
    if (dataCertificate !== null) {
      const feedbackMember = dataCertificate.userComment
      const certificate = dataCertificate.certificate.file
      const openUrl = () => {
        // eslint-disable-next-line
        window.open(`${process.env.REACT_APP_SERVER_API_URL}/files/storage/${certificate}`, '_blank')
      }

      const openModalFeedback = () => {

        const handleOpenFeedback = () => {
          setModalFeedback(true)
          modals.closeAll()
        }

        return modals.openConfirmModal({
          centered: 'true',
          closeOnConfirm: false,
          children: (
            <Box>
              <Box ta='center'>
                <ActionIcon variant='transparent' color='red' size={120}>
                  <IconAlertTriangleFilled stroke={1.5} size={120} />
                </ActionIcon>
                <Text fw={500} mb={4}>Sebelum Klaim Sertifikat, Silahkan Isi Feedback Terlebih Dahulu </Text>
                <Text fz='xs' c='gray.6' mb={20}>Tolong diperhatikan. anda bisa mengklaim sertifikat anda setelah anda mengisi feedback dari program ini.</Text>
              </Box>
            </Box>
          ),
          labels: { confirm: 'Iya', cancel: "Tidak" },
          confirmProps: { color: 'red' },
          onConfirm: () => handleOpenFeedback(),
        })
      }

      return (
        <Flex justify='end' mb={16}>
          <Button size="xs" leftSection={<IconDownload size="1rem" />} color='red' onClick={feedbackMember === null ? openModalFeedback : openUrl}>Klaim Sertifikat</Button>
        </Flex>
      )
    }
  }

  return (
    <Box>
      <Title className={classes.titleHeader}>{detailProgram.eventSchedule.event.kind === 'course' ? 'Video Course' : detailProgram.eventSchedule.name}</Title>
      <Text fz="xs" fw="bold" c="gray.6" mt={4}><Text component='span' fz={13} tt='capitalize'>{detailProgram.eventSchedule.event.kind}</Text>: {detailProgram.eventSchedule.event.name}</Text>
      <Box ta='center' my={24}>
        <Image 
          radius="md"
          h={340}
          w="auto"
          m="auto"
          fit="contain"
          src={detailProgram.eventSchedule.event.eventImages.length > 0 ? getImageFile(detailProgram.eventSchedule.event.eventImages[0].filepath) : NoImage} 
        />
      </Box>
      <Box mt={30}>
        <Accordion variant="separated" radius="md">
          <Accordion.Item value="aboutEvent" style={{ cursor: 'pointer', border: '1px solid #C1C2C5' }}>
            <Accordion.Control icon={<IconInfoSquareRounded size="1.5rem" />}>
              <Text className={classes.titleSection} fw="bold">Tentang <Text className={classes.titleSection} component='span' fw="bold" tt='capitalize'>{detailProgram.eventSchedule.event.kind}</Text></Text>
            </Accordion.Control>
            <Accordion.Panel p={10} style={{ borderTop: '1px solid #C1C2C5' }}>
              <Box mb={16}>
                <Text fz="md" fw="bold">Deskripsi</Text>
                <Text fz="xs">{detailProgram.eventSchedule.event.longDescription ? (<Box dangerouslySetInnerHTML={innerHtml}/>) : '-'}</Text>
              </Box>
              {mappingInfoEvent(detailProgram.eventSchedule.event.kind)}
              {fileRundownTraining(detailProgram.eventSchedule.rundown)}
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="tutorEvent" style={{ cursor: 'pointer', border: '1px solid #C1C2C5' }}>
            <Accordion.Control icon={<IconSchool size="1.5rem" />}>
              <Text className={classes.titleSection} fw="bold">Informasi Tutor</Text>
            </Accordion.Control>
            <Accordion.Panel p={0} style={{ borderTop: '1px solid #C1C2C5' }}>
              <Box m={12}>
                <Grid>
                  {mappingDescriptionTutor(tutorProgram)}
                </Grid>
              </Box>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="certificate" style={{ cursor: 'pointer', border: '1px solid #C1C2C5' }}>
            <Accordion.Control icon={<IconCertificate size="1.5rem" />}>
              <Text className={classes.titleSection} fw="bold">Progress & Sertifikat</Text>
            </Accordion.Control>
            <Accordion.Panel p={10} style={{ borderTop: '1px solid #C1C2C5' }}>
              <Box mb={16}>
                <Grid>
                  <Grid.Col md={12} lg={8}>
                    <Box mb={16}>
                      <Text mb={4} fz="md" fw="bold">Progress</Text>
                      <Progress value={progressProgram.summary.progressPercentage} label={progressProgram.summary.progressPercentage + "%"} size="xl" radius="xl" striped animate color='red' />
                      <Text fz="xs" mt={4}>Anda Telah Menyelesaikan {progressProgram.summary.progressPercentage} dari 100</Text>
                    </Box>
                    <Box mb={16}>
                      <Text mb={4} fz="md" fw="bold">Sertifikat</Text>
                      <Text fz="xs">Anda akan mendapatkan sertifikat setelah dinyatakan lulus oleh Tutor</Text>
                    </Box>
                    {certificateTraining(detailProgram.userEventCompletion)}
                  </Grid.Col>
                </Grid>
              </Box>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Box>
      <Modal opened={modalFeedback} onClose={onCloseModalFeedback} centered closeOnClickOutside={false} size='xl' title={<Text fw='Bold'>Feedback Training</Text>} withCloseButton={false}>
        <FormFeedbackProgram userCompletionId={detailProgram.userEventCompletion !== null ? detailProgram.userEventCompletion.id : null} onCloseModal={onCloseModalFeedback} reloadDetail={handleReload} />
      </Modal>
    </Box>
  )
}

export default InfoProgram