import React from 'react'
import classes from './DetailCouse.module.css'
import { Box, Text } from '@mantine/core'

const InfoCourse = ({ title, desc }) => {
  const innerDescription ={ __html:desc }
  return (
    <Box mb={35}>
      <Text className={classes.titleHeader} mb={10}>{title}</Text>
      <Box>
        <Text fw="bold">Deskripsi</Text>
        <Text fz={14}>{desc ? (<Box dangerouslySetInnerHTML={innerDescription} />) : '-'}</Text>
      </Box>
    </Box>
  )
}

export default InfoCourse