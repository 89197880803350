import React, { useState, useEffect } from 'react'
import classes from '../../../../pages/Member/MemberPage.module.css'
import { Box, Title, Text, Center, Loader, Button, Flex, Grid, Avatar, Card, TextInput, Group, Menu, ActionIcon, Tooltip, ThemeIcon, HoverCard, Stack, Badge } from '@mantine/core'
import { getDiscussionList, deleteDiscussion } from '../../../../services/discussion'
import { getAnswerDiscussionList } from '../../../../services/answerDiscussion'
import { IconDots, IconTrash, IconEdit, IconMessage2, IconMessages } from '@tabler/icons-react'
import { useScrollIntoView } from '@mantine/hooks'
import FormDiscussionProgram from './FormDiscussionProgram'
import AnswerDiscussionProgram from './AnswerDiscussionProgram'
import FormAnswerDiscussionProgram from './FormAnswerDiscussionProgram'
import { getImageFile } from '../../../../services/fileImage'
import dayjs from 'dayjs'
import { modalDeleteData } from '../../../ui/ModalManager/modalDeleteData'
import { useSelector } from 'react-redux'


const LoadingQuestion = () => {
  return (
    <Box pos="relative"> 
      <Center mih={500} mx="auto">
        <Loader size="xl" />
      </Center>
    </Box>
  )
}


const DiscussionProgram = ({ detailProgram }) => {
  const loginData = useSelector(state => state.member.data)
  const { scrollIntoView, targetRef } = useScrollIntoView({
    offset: 60
  })
  const defaultParameter = {
    eventSchedule: detailProgram.eventSchedule.id,
    search: '',
    orderBy: 'createdAt',
    order: 'desc'
  }
  const [parameter, setParameter] = useState(defaultParameter)
  const [addQuestion, setAddQuestion] = useState(false)
  const [discussionList, setDiscussionList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [detailDiscussion, setDetailDiscussion] = useState(null)

  const handleGetDiscussionList = async () => {
    setLoading(true)
    try {
      const response = await getDiscussionList(parameter)
      const dataDiscussion = response.data
      const remapDataDiscussion = await Promise.all(dataDiscussion.map(async (val, index) => {
        const responseAnswer = await handleGetAnswerDiscussionList(val.id)
        const dataAnswer = responseAnswer.data
        const countAnswer = responseAnswer.count
        const remapDataAnswer = dataAnswer.map((val) => {
          return {
            ...val,
            editAnswer: false
          }
        })
        return {
          ...val,
          editDiscussion: false,
          adjustAnswerDiscussion: false,
          answerList: remapDataAnswer,
          answerCount: countAnswer
        }
      }))
      setDiscussionList(remapDataDiscussion)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetAnswerDiscussionList = async (discussionId) => {
    const params = {
      discussion: discussionId,
      orderBy: 'createdAt',
      order: 'asc'
    }
    try {
      const response = await getAnswerDiscussionList(params)
      if (response) {
        return response
      }
      return response
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetDiscussionList()
    // eslint-disable-next-line
  }, [parameter])

  useEffect(() => {
    if (addQuestion) {
      scrollIntoView({alignment: 'center'})
    }
    // eslint-disable-next-line
  }, [addQuestion])

  const handleFilter = (name, val) => {
    setParameter((oldVal) => ({ ...oldVal, [name]: val}))
  }

  const handleEditDiscussion = (val, index) => {
    const data = discussionList
    data[index].editDiscussion = true
    setDiscussionList([...data])
    setDetailDiscussion(val)
  }

  const cancelEditDiscussion = (index) => {
    const data = discussionList
    data[index].editDiscussion = false
    setDiscussionList([...data])
    setDetailDiscussion(null)
  }

  const handleDeleteDiscussion = (val) => {
    modalDeleteData('Diskusi', val.id, val.title, deleteDiscussion, handleGetDiscussionList)
  }

  const handleReplyDiscussion = (valDiscussion, index) => {
    const data = discussionList
    data[index].adjustAnswerDiscussion = true
    setDiscussionList([...data])
    setDetailDiscussion(valDiscussion)
  }

  const handleCancelReplyDiscussion = (index) => {
    const data = discussionList
    data[index].adjustAnswerDiscussion = false
    setDiscussionList([...data])
    setDetailDiscussion(null)
  }

  const showAdjustAnswerDiscussion = (valueDiscussion, index) => {
    const dataAnswer = valueDiscussion.answerList

    return (
      <Box>
        <Grid justify='center'>
          <Grid.Col span={11}>
            {dataAnswer ? (<AnswerDiscussionProgram detailDiscussion={valueDiscussion} dataAnswer={dataAnswer} reloadList={handleGetDiscussionList} />) : ''}
          </Grid.Col>
          {valueDiscussion.adjustAnswerDiscussion ? (<Grid.Col span={11}><FormAnswerDiscussionProgram detailDiscussion={detailDiscussion} detailAnswer={null} cancelReplyQuestion={() => handleCancelReplyDiscussion(index)} handleReload={handleGetDiscussionList}  /></Grid.Col>) : ''}
        </Grid>
      </Box>
    )
  }

  const mappingDiscussion = (data) => {
    if (data.length === 0) {
      return (
        <Box ta='center' py={60}>
          <Text c="dark">Program ini belum memiliki diskusi</Text>
        </Box>
      )
    } else {
      // eslint-disable-next-line
      const remapDisccussion = data.map((val, index) => {
        const innerHtmlDiscussion = { __html: val.content }

        const permissionAdjustment = (userId) => {
          if (loginData.id === userId) {
            return (
              <Menu withinPortal position="bottom-end" shadow="sm">
                <Menu.Target>
                  <ActionIcon variant="subtle" color="gray">
                    <IconDots size={14} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item 
                    leftSection={<IconEdit size={14} />}
                    fz={11}
                    onClick={() => handleEditDiscussion(val, index)}
                  >
                    Edit Diskusi
                  </Menu.Item>
                  <Menu.Item
                    leftSection={<IconTrash size={14} />}
                    color="red"
                    fz={11}
                    onClick={() => handleDeleteDiscussion(val)}
                  >
                    Hapus Diskusi
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )
          }
        }

        if (!val.editDiscussion) {
          if (loginData.id === val.userEvent.user.id) {
            return (
              <Box mb={30}>
                <Grid type="container" justify="flex-end" mb={12}>
                  <Grid.Col span={11} order={{ base: 2, sm: 1, md: 1, lg: 1 }}>
                    <Card pos='relative' withBorder shadow="md" radius="md">
                      <Card.Section withBorder inheritPadding py="xs">
                        <Group justify='space-between' align='start'>
                          <Box>
                            <Text fz={12} mb={3}>Topik Diskusi:</Text>
                            <Text fz={12} fw='600'>{val.title}</Text>
                          </Box>
                          <Group gap={4}>
                            <Text fz={12} c='dimmed'>{dayjs(val.createdAt).format('DD MMMM YYYY')}</Text>
                            {permissionAdjustment(val.userEvent.user.id)}
                          </Group>
                        </Group>
                      </Card.Section>
                      <Text fz={11} fw='600' mt={8}>{val.userEvent.user.fullName}: </Text>
                      <Box component='p' style={{ width: '100%', margin:'0px' }} className="ck-content" dangerouslySetInnerHTML={innerHtmlDiscussion} />
                      <Card.Section inheritPadding p="md">
                        <Group justify='start' align='center' gap={1}>
                          <Tooltip label="Balas diskusi ini" position="top" withArrow>
                            <ThemeIcon variant='white' color='gray' p={0} style={{ cursor: 'pointer', justifyContent: 'none' }} onClick={() => handleReplyDiscussion(val, index)}>
                              <IconMessage2 size={14} />
                            </ThemeIcon>
                          </Tooltip>
                          <Group gap={1} justify='center' align='center'>
                            <ThemeIcon variant='white' color='gray' p={0} style={{ justifyContent: 'center' }}>
                              <IconMessages size={14} />
                            </ThemeIcon>
                            <Text fz={12} c='dimmed'>{val.answerCount} Balasan</Text>
                          </Group>
                        </Group>
                      </Card.Section>
                    </Card>
                  </Grid.Col>
                  <Grid.Col span='content' order={{ base: 1, sm: 2, md: 2, lg: 2 }}>
                    <Group>
                      <HoverCard width={250} shadow="md" withArrow openDelay={200} closeDelay={400}>
                        <HoverCard.Target>
                          <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Group>
                            <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                            <Stack gap={5}>
                              <Text size="sm" fw={700} lineClamp={1} style={{ lineHeight: 1 }}>
                                {val.userEvent.user.fullName}
                              </Text>
                              <Badge variant="light" color="red" size="xs" tt='capitalize'>{!val.userEvent.isTutor ? 'Member' : 'Tutor'}</Badge>
                            </Stack>
                          </Group>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </Group>
                  </Grid.Col>
                </Grid>
                {showAdjustAnswerDiscussion(val, index)}
              </Box>
            )
          } else {
            return (
              <Box mb={30}>
                <Grid type="container" justify="flex-start" mb={12}>
                  <Grid.Col span='content'>
                    <Group>
                      <HoverCard width={250} shadow="md" withArrow openDelay={200} closeDelay={400}>
                        <HoverCard.Target>
                          <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                        </HoverCard.Target>
                        <HoverCard.Dropdown>
                          <Group>
                            <Avatar size='md' src={val.userEvent.user.image !== null ? getImageFile(val.userEvent.user.image) : null} />
                            <Stack gap={5}>
                              <Text size="sm" fw={700} lineClamp={1} style={{ lineHeight: 1 }}>
                                {val.userEvent.user.fullName}
                              </Text>
                              <Badge variant="light" color="red" size="xs" tt='capitalize'>{!val.userEvent.isTutor ? 'Member' : 'Tutor'}</Badge>
                            </Stack>
                          </Group>
                        </HoverCard.Dropdown>
                      </HoverCard>
                    </Group>
                  </Grid.Col>
                  <Grid.Col span={11}>
                    <Card pos='relative' withBorder shadow="md" radius="md">
                      <Card.Section withBorder inheritPadding py="xs">
                        <Group justify='space-between' align='start'>
                          <Box>
                            <Text fz={12} mb={3}>Topik Diskusi:</Text>
                            <Text fz={12} fw='600'>{val.title}</Text>
                          </Box>
                          <Group gap={4}>
                            <Text fz={12} c='dimmed'>{dayjs(val.createdAt).format('DD MMMM YYYY')}</Text>
                            {permissionAdjustment(val.userEvent.user.id)}
                          </Group>
                        </Group>
                      </Card.Section>
                      <Text fz={11} fw='600' mt={8}>{val.userEvent.user.fullName}: </Text>
                      <Box component='p' style={{ width: '100%', margin:'0px' }} className="ck-content" dangerouslySetInnerHTML={innerHtmlDiscussion} />
                      <Card.Section inheritPadding p="md">
                        <Group justify='start' align='center' gap={1}>
                          <Tooltip label="Balas diskusi ini" position="top" withArrow>
                            <ThemeIcon variant='white' color='gray' p={0} style={{ cursor: 'pointer', justifyContent: 'none' }} onClick={() => handleReplyDiscussion(val, index)}>
                              <IconMessage2 size={14} />
                            </ThemeIcon>
                          </Tooltip>
                          <Group gap={1} justify='center' align='center'>
                            <ThemeIcon variant='white' color='gray' p={0} style={{ justifyContent: 'center' }}>
                              <IconMessages size={14} />
                            </ThemeIcon>
                            <Text fz={12} c='dimmed'>{val.answerCount} Balasan</Text>
                          </Group>
                        </Group>
                      </Card.Section>
                    </Card>
                  </Grid.Col>
                </Grid>
                {showAdjustAnswerDiscussion(val, index)}
              </Box>
            )
          }
        } else {
          return (
            <Box my={30}>
              <FormDiscussionProgram detail={detailDiscussion} cancelAddQuestion={() => cancelEditDiscussion(index)} handleReload={handleGetDiscussionList} />
            </Box>
          )
        }
      })
      return (
        <Box my={40}>
          {remapDisccussion}
        </Box>
      )
    }
  }
  
  return (
    <Box>
      <Title className={classes.titleHeader}>Diskusi {detailProgram.eventSchedule.event.name}</Title>
      <Text fz="xs" fw="bold" c="gray.6" mt={4}><Text component='span' fz={13} tt='capitalize' mr={2}>Total Diskusi :</Text> {count}</Text>
      <Flex justify='space-between' align='center' my={12}>
        <TextInput
          placeholder='Cari Diskusi. . .'
          name="search"
          onChange={(val) => handleFilter('search', val.target.value)}
          size='xs'
        />
        {detailDiscussion === null ? <Button size='xs' color='red' onClick={() => [setAddQuestion(true), setDetailDiscussion(null)]}>Tambah Diskusi</Button> : ''}
      </Flex>
      <Box my={30} px={40}>
        {loading ? (<Box mih={500} pos='relative'><LoadingQuestion /></Box>) : mappingDiscussion(discussionList)}
      </Box>
      <Box pos='absolute' ref={targetRef} style={{ height: '70vh' }}/>
      {addQuestion ? (<Box my={50}> <FormDiscussionProgram detail={detailDiscussion} cancelAddQuestion={() => setAddQuestion(false)} handleReload={handleGetDiscussionList} /> </Box> ) : ''}
    </Box>
  )
}

export default DiscussionProgram